import { React, useState } from "react";

import classes from "./CSVReader.module.css";

import appIcon from "../../images/app_icon.png";

const CSVReader = (props) => {
  const [highlighted, setHighlighted] = useState(false);

  return (
    <div
      className={highlighted ? classes.CSVReaderHighlighted : classes.CSVReader}
      onDragEnter={() => {
        setHighlighted(true);
      }}
      onDragLeave={() => {
        setHighlighted(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setHighlighted(true);
      }}
      onDrop={async (e) => {
        e.preventDefault();
        setHighlighted(false);

        Array.from(e.dataTransfer.files)
         // .filter((file) => file.type === "application/vnd.ms-excel" || file.type === "text/cvs")
          .forEach(async (file) => {
            const textfile = await file.text();
            props.recieveData(textfile);
          });
      }}
    >
      <div className={classes.centered}>
        <img className={classes.shakeImage} src={appIcon} alt={"App-Icon"} />
      </div>
      <h3 className={classes.centeredtwo}>To start drop a csv-file here!</h3>
    </div>
  );
};

export default CSVReader;
