import classes from "./MultiImageDropField.module.css";

import multidropimage from "../../images/multidrop.png";
import { useState } from "react";

const MultiImageDropField = (props) => {
  const [cssClass, setCssClass] = useState(classes.multiDropBar);
  return (
    <div
      className={cssClass}
      onDragOver={(e) => {
        e.preventDefault();
        setCssClass(classes.multiDropBarHovered);
      }}
      onDrag={(e) => {
        e.preventDefault();
        setCssClass(classes.multiDropBarHovered);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setCssClass(classes.multiDropBar);
      }}
      onDrop={(e) => {
        e.preventDefault();
        setCssClass(classes.multiDropBar);
        let imageArray = [];
        Array.from(e.dataTransfer.files)
          .filter(
            (file) => file.type === "image/png" || file.type === "image/jpeg"
          )
          .forEach(async (file, index) => {
            imageArray.push(file);
          });
        const data = { images: imageArray };
        props.alterImages(data);
      }}
    >
      <img src={multidropimage} alt="multiimagedropfield" />
    </div>
  );
};

export default MultiImageDropField;
