import React, { useState } from "react";

import classes from "./Settings.module.css";

const Settings = (props) => {
  var percentage = 0;
  if (props.numberOfImages > 0) {
    percentage =
      parseInt(props.exportedImages) / parseInt(props.numberOfImages);
  } else {
    percentage = 0;
  }

  const [disabled, setDisabled] = useState(true);
  const numberOfImages = parseInt(props.numberOfElements) + 1;
  const changeImageSize = (e) => {
    props.OnChangeImageSize(e.target.value);
  };

  var cssClass = classes.defaultstatistics;
  if (props.exportStarted) {
    cssClass = classes.defaultstatistics;
  } else {
    cssClass = classes.statisticshidden;
  }

  const DefaultImageSizes = (e) => {
    props.OnChangeImageSize(parseInt(e.target.id));
  };

  const HandleSlider = (e) => {
    props.OnChangeNumberOfElements(e.target.value);
  };

  const Export = () => {
    props.exportData();
  };

  const HandleFilterState = (e) => {
    props.OnChangeFilterState(e.target.value);
    if (e.target.value === "hideNumber") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <div className={classes.settings}>
      <h1>Settings</h1>
      <div className={classes.visibilityContainer}>
        <h2 className={classes.firsth3}>Visibility</h2>
        <div>
          <input
            className={classes.radiobutton}
            type="radio"
            id="showAll"
            name="visibility"
            value="showAll"
            onClick={HandleFilterState}
          />
          <label htmlFor="showAll">show all.</label>
          <br></br>
          <input
            className={classes.radiobutton}
            type="radio"
            id="hideReady"
            name="visibility"
            value="hideReady"
            onClick={HandleFilterState}
          />
          <label htmlFor="hideReady">hide "ready" elements.</label>
          <br></br>
          <input
            className={classes.radiobutton}
            type="radio"
            id="countElements"
            name="visibility"
            value="hideNumber"
            onClick={HandleFilterState}
          />
          <label htmlFor="countElements">
            set elements "ready" with {numberOfImages} or more image(s).
          </label>
          <br></br>
          <input
            type="range"
            min="0"
            max="5"
            id="rangeValue"
            value={props.numberOfElements}
            onChange={HandleSlider}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={classes.indented}>
        <h2 className={classes.h3}>Image size</h2>
        <label htmlFor="imagesize">Minimum width:</label>
        <input
          type="text"
          className={classes.input}
          onChange={changeImageSize}
          value={props.value}
          id="imagesize"
          placeholder="Enter the minimum width of images"
        />
        <p>Default sizes:</p>
        <div className={classes.divdefaultsizebuttons}>
          <input
            className={classes.defaultsizebuttons}
            type="button"
            id="2048"
            onClick={DefaultImageSizes}
            value="2048"
          />
          <input
            className={classes.defaultsizebuttons}
            type="button"
            id="1024"
            onClick={DefaultImageSizes}
            value="1024"
          />
          <input
            className={classes.defaultsizebuttons}
            type="button"
            id="512"
            onClick={DefaultImageSizes}
            value="512"
          />
          <input
            className={classes.defaultsizebuttons}
            type="button"
            id="256"
            onClick={DefaultImageSizes}
            value="256"
          />
        </div>
      </div>
      <div className={classes.visibilityContainer}>
        <h2 className={classes.h3}>Export</h2>
        <input
          className={classes.exportbutton}
          type="button"
          value="Export all images to disc"
          onClick={Export}
        />
        <p className={cssClass}>
          Exported {props.exportedImages} of {props.numberOfImages} images.
        </p>
        <progress className={cssClass} value={percentage} max="1"></progress>
      </div>
      <div className={classes.visibilityContainer}>
        <h2 className={classes.h3}>Statistics</h2>
        <table>
          <tbody>
            <tr className={classes.tablerow}>
              <td className={classes.tableheader}>Amount of images:</td>
              <td className={classes.tablecontent}>{props.numberOfImages}</td>
            </tr>
            <tr className={classes.tablerow}>
              <td className={classes.tableheader}>Items total:</td>
              <td className={classes.tablecontent}> {props.numberOfItems}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Settings;
