import { useState } from "react";
import Resizer from "react-image-file-resizer";

import classes from "./ListItem.module.css";

import ImageDropField from "./ImageDropField";
import noPreview from "../../images/image_unset.png";
import MultiImageDropField from "./MultiImageDropField";

const ListItem = (props) => {
  const isCombinedProduct = props.combinedProduct;
  //Number of current-images
  const [currentImages, setCurrentImages] = useState(0);
  var listID = props.id;
  //Show the whole ListItem, or just the info bar.
  const [ready, setReady] = useState(false);
  const [readyByApp, setReadyByApp] = useState(false);
  const [hovered, setHovered] = useState(false);
  //handles visibility of this ListItem
  var filter = props.visibilityFilter;
  var cssClasses = classes.listitemOpen;
  if ((filter === "showAll" || filter === "hideReady") && readyByApp) {
    setReadyByApp(false);
  }
  if (filter === "showAll") {
    cssClasses = classes.listitemOpen;
    if (ready) {
      cssClasses = classes.listitemClosed;
    }
  } else if (filter === "hideReady") {
    if (ready) {
      cssClasses = classes.listitemHidden;
    }
  } else if (filter === "hideNumber") {
    if (props.numberOfElements < currentImages && !readyByApp) {
      setReadyByApp(true);
    } else if (props.numberOfElements >= currentImages && readyByApp) {
      setReadyByApp(false);
    }
    if ((readyByApp && !hovered) || ready) {
      cssClasses = classes.listitemClosed;
    }
  }

  var combinedString = (listID + " " + props.name + " " + props.description).toLowerCase();
  var searchValues = [" ", " "];
  searchValues = props.searchValue.toLowerCase().split(" ");
  let includesSearch = true;
  searchValues.forEach((element) => {
    if (!combinedString.includes(element.toLowerCase())) {
      includesSearch = false;
    }
  });
  if (!includesSearch) {
    cssClasses = classes.listitemHidden;
  }

  //Number of max-images
  const numberOfImages = 6;
  //Preview-Images stored in an array
  const [previewImages, setPreviewImages] = useState([
    noPreview,
    noPreview,
    noPreview,
    noPreview,
    noPreview,
    noPreview,
  ]);
  //variable-array to save the current position of deletable images.
  const [deletablePreview, setDeletablePreview] = useState([false, false, false, false, false, false]);

  //-----------------------------------------
  //alters the ready-state of this list-item
  //-----------------------------------------
  const ReadyListItem = () => {
    setReady(!ready);
  };

  //-----------------------------------------
  //Deletes the preview image and the archive-image in the app.js
  //-----------------------------------------
  const DeletePreview = (content) => {
    //im Bilderarray das Bild zurücksetzen
    var newImageArray = previewImages;
    newImageArray[content] = noPreview;
    setPreviewImages(newImageArray);
    //deletable preview zurücksetzen
    var newDeletablePreview = deletablePreview;
    newDeletablePreview[content] = false;
    setDeletablePreview(newDeletablePreview);
    //Das Hauptbild in App.js löschen
    const data = { id: listID, index: content };
    props.deleteImageFromDB(data);

    //Current images einen runter setzen.
    var newCurrentImages = currentImages;
    if (newCurrentImages > 0) {
      newCurrentImages--;
    }
    setCurrentImages(newCurrentImages);
  };

  //-----------------------------------------
  //Recieves an image -> makes a preview  -> sends the original image to the app.js image-archive -> counts currentImages Up!
  //-----------------------------------------
  const NewImage = (content) => {
    //Storage old array of previewImages.
    var newArray = previewImages;
    var newImage = "";
    if (newArray[content.id] === noPreview) {
      var numberOfCurrentImages = currentImages;
      numberOfCurrentImages++;
      setCurrentImages(numberOfCurrentImages);
    }
    try {
      Resizer.imageFileResizer(
        content.image,
        220,
        125,
        "PNG",
        100,
        0,
        (uri) => {
          newImage = uri;
          newArray[content.id] = newImage;
          setPreviewImages(newArray);
          ReadyListItem();
          setReady(false);
        },
        "uri"
      );
    } catch (err) {
      console.log(err);
    }
    var newDeletion = deletablePreview;
    newDeletion[content.id] = true;
    setDeletablePreview(newDeletion);
    //Pass the image and data to the App.js to storage.
    var dataImage = { id: listID, image: content.image, index: content.id };
    props.addNewImageToDB(dataImage);
    props.countImagesUp(1);
  };

  //-----------------------------------------
  //Recieves an array of images -> makes a preview  -> sends the original image to the app.js image-archive -> counts currentImages Up!
  //-----------------------------------------
  const NewImageArray = async (content) => {
    var helperArray = [];
    var numberOfCurrentImages = currentImages;
    //Number of empty image slots.
    let imageSlots = [];
    previewImages.forEach((image, index) => {
      if (image === noPreview) {
        imageSlots.push(index);
      }
    });
    var newArray = previewImages;
    var myImageArray = content.images;
    var quantity = 0;
    if (imageSlots.length >= myImageArray.length) {
      quantity = myImageArray.length;
    } else if (imageSlots.length < myImageArray.length) {
      quantity = imageSlots.length;
    }
    const test = myImageArray.forEach(async (file, index) => {
      var correctIndex = imageSlots[index];
      if (index < imageSlots.length) {
        try {
          Resizer.imageFileResizer(
            file,
            220,
            125,
            "PNG",
            100,
            0,
            (uri) => {
              newArray[correctIndex] = uri;
              helperArray.push(correctIndex);
              setPreviewImages(newArray);
              var newDeletion = deletablePreview;
              newDeletion[correctIndex] = true;
              setDeletablePreview(newDeletion);
              var dataImage = { id: listID, image: file, index: correctIndex };
              props.addNewImageToDB(dataImage);
              ReadyListItem();
              setReady(false);
            },
            "uri"
          );
        } catch (err) {
          console.log(err);
        }
        numberOfCurrentImages++;
      }
    });
    await test;
    props.countImagesUp(quantity);
    setCurrentImages(numberOfCurrentImages);
  };

  //---------------------------------
  //Alters a single image and adds it to the lib in app.js
  //---------------------------------
  const AlterImage = (props) => {
    NewImage(props);
  };

  //---------------------------------
  //Alters an array of images and adds them to the lib in app.js
  //---------------------------------
  const AlterImages = (props) => {
    NewImageArray(props);
  };
  //---------------------------------
  //Switches a preview- and data-image to the first position in the imagedata-list.
  //---------------------------------
  const MakeFirstImage = (content) => {
    console.log(previewImages);
    var imageID = content;
    var images = previewImages;
    var oldFirstImage = previewImages[0];
    var newFirstImage = previewImages[imageID];
    images[0] = newFirstImage;
    images[imageID] = oldFirstImage;
    setPreviewImages(images);
    if (oldFirstImage === noPreview) {
      var booleans = deletablePreview;
      booleans[0] = true;
      booleans[imageID] = false;
      setDeletablePreview(booleans);
    }
    const data = { list: listID, id: imageID, swapIndex: 0 };
    props.swapImages(data);
  };
  //---------------------------------
  //Switches a preview- and data-image to the second position in the imagedata-list.
  //---------------------------------
  const MakeSecondImage = (content) => {
    console.log(previewImages);
    var imageID = content;
    var images = previewImages;
    var oldSecondImage = previewImages[1];
    var newSecondImage = previewImages[imageID];
    images[1] = newSecondImage;
    images[imageID] = oldSecondImage;
    setPreviewImages(images);
    console.log(images);
    if (oldSecondImage === noPreview) {
      var booleans = deletablePreview;
      booleans[1] = true;
      booleans[imageID] = false;
      setDeletablePreview(booleans);
    }
    const data = { list: listID, id: imageID, swapIndex: 1 };
    props.swapImages(data);
  };

  const copyID = () => {
    navigator.clipboard.writeText(props.id);
  };

  //HTML ELEMENTS
  return (
    <div
      className={cssClasses}
      onMouseOver={(e) => {
        setHovered(true);
      }}
      onMouseLeave={(e) => {
        setHovered(false);
      }}
      onDragEnter={(e) => {
        setHovered(true);
      }}
      onDragLeave={(e) => {
        setHovered(false);
      }}
    >
      {/* TopBar */}
      <div className={classes.topBar}>
        {<p className={classes.infoID}>{props.name}</p>}
        <div className={classes.infoName}>
          <h3 className={classes.headerID}>{props.id}</h3>
          <button className={classes.buttonID} onClick={copyID}>
            Copy
          </button>
        </div>
        <div className={classes.toolbar}>
          <label className={classes.label} htmlFor='checkbox'>
            Ready
          </label>
          <input
            className={classes.infoCheckbox}
            id='checkbox'
            checked={ready}
            type='checkbox'
            onChange={ReadyListItem}
          />
          <p className={classes.infoNumber}>images: {currentImages}</p>
        </div>

        <div className={classes.multidrop}>
          <MultiImageDropField alterImages={AlterImages} />
        </div>
      </div>
      <div>
        <ol className={classes.orderedlist}>
          {Array.from(Array(numberOfImages), (e, i) => {
            return (
              <ImageDropField
                uniqueKey={props.id}
                key={listID + i}
                id={i}
                visible={!ready && (!readyByApp || (readyByApp && hovered))}
                previewImage={previewImages[i]}
                alterImage={AlterImage}
                delete={deletablePreview[i]}
                deletePreview={DeletePreview}
                makeFirstImage={MakeFirstImage}
                makeSecondImage={MakeSecondImage}
              />
            );
          })}
        </ol>
      </div>
    </div>
  );
};
export default ListItem;
