import classes from "./SearchBar.module.css";
const SearchBar = (Props) => {
  const HandleSearchEntry = (e) => {
    Props.setSearchStatement(e.target.value);
  };
  return (
    <div className={classes.searchbar}>
      <div className={classes.content}>
        <label className={classes.label} htmlFor="searchbar">
          Sucheingabe:{" "}
        </label>
        <input
          className={classes.textfield}
          type="text"
          id="searchbar"
          onChange={HandleSearchEntry}
        />
      </div>
    </div>
  );
};

export default SearchBar;
