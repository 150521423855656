import { useState } from "react";
import { parse } from "papaparse";
import Resizer from "react-image-file-resizer";
import FileSaver from "file-saver";

import classes from "./App.module.css";

import "./App.css";
import Header from "./components/layout/Header";
import SideBar from "./components/layout/Sidebar";
import SearchBar from "./components/searchBar/SearchBar";
import ListItem from "./components/items/ListItem";
import CSVReader from "./components/csvReader/CSVReader";
import CSVOptions from "./components/csvReader/CSVOptions";

function App() {
  //name of current version
  const versionName = "V3.1.3 - Production";
  //save the state of the option menu
  const [optionsOpen, setOptionsOpen] = useState(false);
  //let the app know if data has been dropped correctly.
  const [data, setData] = useState(false);
  //alleEntries - saves all Data coming from the csv file. It includes name, description and image-data(empty at creation).
  const [allEntries, setAllEntries] = useState([]);
  //letiable to store the minimum width of an image.
  const [imageSize, setImageSize] = useState(1000);
  //letiable to set the number of elements, to filter.
  const [numberOfElements, setNumberOfElements] = useState(1);
  //enum Filter State
  const enumFilter = {
    SHOWALL: "showAll",
    HIDEREADY: "hideReady",
    HIDENUMBER: "hideNumber",
  };
  //Modal visibility
  const [exportedImages, setExportedImages] = useState(0);
  const [exportStarted, setExportStarted] = useState(false);
  //Filter state
  const [filter, setFilter] = useState(enumFilter.SHOWALL);
  //SearchSatements entered by user is stored
  const [searchStatement, setSearchStatement] = useState("");
  let rawData = [];

  //staticstic letiables
  const [numberOfImages, setNumberOfImages] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(0);

  //CSV Settings
  const amounts = {
    FULL: "full",
    FIRSTHALF: "firsthalf",
    SECONDHALF: "secondhalf",
    FIRSTQUARTER: "firstqurater",
    SECONDQUARTER: "secondquarter",
    THIRDQUARTER: "thirdquarter",
    FOURTHQUARTER: "fourthquarter",
  };

  const [importAmount, setImportAmount] = useState(amounts.FULL);

  //SETTINGS FUNCTIONS
  //Function to store the image size
  const OnChangeImageSize = (props) => {
    setImageSize(props);
  };
  //Function to store the number of elements, before listitems will be hidden
  const OnChangeNumberOfElements = (props) => {
    setNumberOfElements(props);
  };

  //Function to save new filter state
  const OnChangeFilterState = (props) => {
    setFilter(props);
  };
  //CSV-Data recieved
  const RecieveData = async (props) => {
    rawData = props;
    console.log(props);
    setData(true);
    const data = parse(rawData, {
      header: true,
      skipEmptyLines: true,
    }).data;
    let length = data.length;
    let min = CalculateMin(length);
    let max = CalculateMax(length);
    setNumberOfItems(max - min);
    let result = [
      {
        id: "123",
        name: "Test",
        description: "Beschreibungstext",
        imagedata: ["", "", "", "", "", ""],
      },
    ];
    data.forEach((element, index) => {
      console.log(element.ID);
      if (index >= min && index < max) {
        element.ID !== ""
          ? result.push({
              id: element.ID,
              name: element.ArtBezShop || "",
              description: element.Farbe || "",
              imagedata: ["", "", "", "", "", ""],
            })
          : console.log("Kein Eintrag gefunden");
      }
    });
    result.shift();
    setAllEntries(result);
    setData(true);
  };

  const CalculateMin = (length) => {
    switch (importAmount) {
      case amounts.FULL:
        return 0;
      case amounts.FIRSTHALF:
        return 0;
      case amounts.SECONDHALF:
        //ungerade
        if (length % 2 !== 0) {
          return (length - 1) / 2;
        } else {
          return length / 2;
        }
      case amounts.FIRSTQUARTER:
        return 0;
      case amounts.SECONDQUARTER:
        return parseInt(length / 4);
      case amounts.THIRDQUARTER:
        return parseInt(length / 4) * 2;
      case amounts.FOURTHQUARTER:
        let rest = length % 4;
        return parseInt((length / 4) * 3 + rest);
      default:
        return 0;
    }
  };

  const CalculateMax = (length) => {
    switch (importAmount) {
      case amounts.FULL:
        return length;
      case amounts.FIRSTHALF:
        if (length % 2 !== 0) {
          return (length - 1) / 2;
        } else {
          return length / 2;
        }
      case amounts.SECONDHALF:
        return length;
      case amounts.FIRSTQUARTER:
        return length / 4;
      case amounts.SECONDQUARTER:
        return (length / 4) * 2;
      case amounts.THIRDQUARTER:
        return (length / 4) * 3;
      case amounts.FOURTHQUARTER:
        return length;
      default:
        return length;
    }
  };

  const RecieveSearchStatement = (props) => {
    setSearchStatement(props);
  };

  const AddNewImageToDB = async (props) => {
    let DB = allEntries;
    let alteredObject = DB.find((item) => item.id === props.id);
    alteredObject.imagedata[props.index] = props.image;
    let objectIndex = DB.indexOf(DB.find((item) => item.id === props.id));
    DB[objectIndex] = alteredObject;
    setAllEntries(DB);
  };

  const DeleteImageFromDB = (props) => {
    let DB = allEntries;
    let alteredObject = DB.find((item) => item.id === props.id);
    alteredObject.imagedata[props.index] = "";
    let objectIndex = DB.indexOf(DB.find((item) => item.id === props.id));
    DB[objectIndex] = alteredObject;
    setAllEntries(DB);
    CountImagesDown();
  };

  const CountImagesUp = (props) => {
    let number = numberOfImages;
    setNumberOfImages(props + number);
  };
  const CountImagesDown = () => {
    let number = numberOfImages;
    setNumberOfImages(--number);
  };

  const AlterOptionsMenu = () => {
    let newBoolean = !optionsOpen;
    setOptionsOpen(newBoolean);
  };
  const CloseOptionsMenu = () => {
    if (optionsOpen) {
      setOptionsOpen(false);
    }
  };

  const SwapImages = (data) => {
    let DB = allEntries;
    let alteredObject = DB.find((item) => item.id === data.list);
    let images = alteredObject.imagedata;
    const newImage = images[data.id];
    const oldImage = images[data.swapIndex];
    images[data.id] = oldImage;
    images[data.swapIndex] = newImage;
    alteredObject.imagedata = images;
    let objectIndex = DB.indexOf(DB.find((item) => item.id === data.list));
    DB[objectIndex] = alteredObject;
    setAllEntries(DB);
  };

  const ExportData = async () => {
    setExportedImages(0);
    setExportStarted(true);
    let i = 0;
    let fileName = "";
    let newExportedAmount = 0;
    await Delay(100);
    do {
      let currentEntry = allEntries[i];
      await Delay(250);
      let j = 0;
      do {
        let currentImage = currentEntry.imagedata[j];
        if (currentImage !== "") {
          fileName = currentEntry.id + "_00" + (j + 1) + ".png";
          await Delay(100);
          await ResizeAndExportImage(currentImage, fileName);
          await Delay(500);
          newExportedAmount++;
          setExportedImages(newExportedAmount);
          await Delay(100);
        }
        j++;
      } while (j < currentEntry.imagedata.length);
      i++;
    } while (i < allEntries.length);
    await Delay(5000);
    setExportedImages(0);
    setExportStarted(false);
  };

  const ResizeAndExportImage = async (image, fileName) => {
    let scaledImage = ScaleImage(image).then((result) => (scaledImage = result));
    await scaledImage;
    let saved = SaveFile(scaledImage, fileName);
    await saved;
  };

  const Delay = (milliseconds) =>
    new Promise((promise) => {
      setTimeout(promise, milliseconds);
    });

  const SaveFile = async (newImage, fileName) => {
    FileSaver.saveAs(newImage, fileName);
  };

  const ScaleImage = (props) =>
    new Promise((resolve) => {
      try {
        Resizer.imageFileResizer(
          props,
          imageSize,
          imageSize,
          "PNG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      } catch (err) {
        console.log(err);
      }
    });

  const HandleAmount = (props) => {
    console.log(props);
    setImportAmount(props);
  };

  return (
    <div>
      {data ? (
        <div className={classes.content}>
          <div onClick={CloseOptionsMenu}>
            <Header />
            <SearchBar setSearchStatement={RecieveSearchStatement} />
          </div>
          <SideBar
            OnChangeImageSize={OnChangeImageSize}
            value={imageSize}
            numberOfElements={numberOfElements}
            OnChangeNumberOfElements={OnChangeNumberOfElements}
            OnChangeFilterState={OnChangeFilterState}
            numberOfImages={numberOfImages}
            optionsMenuOpen={optionsOpen}
            alterOptionsMenu={AlterOptionsMenu}
            exportData={ExportData}
            exportedImages={exportedImages}
            exportStarted={exportStarted}
            numberOfItems={numberOfItems}
          />
          <div onClick={CloseOptionsMenu}>
            {allEntries.map((entry, index) => (
              <ListItem
                id={entry.id}
                name={entry.name}
                description={entry.description}
                combinedProduct={entry.combinedProduct}
                searchValue={searchStatement}
                visibilityFilter={filter}
                numberOfElements={numberOfElements}
                addNewImageToDB={AddNewImageToDB}
                deleteImageFromDB={DeleteImageFromDB}
                countImagesUp={CountImagesUp}
                swapImages={SwapImages}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={classes.startscreen}>
          <p className={classes.version}>{versionName}</p>
          <CSVReader recieveData={RecieveData} />
          <CSVOptions handleAmount={HandleAmount} />
        </div>
      )}
      <br />
    </div>
  );
}

export default App;
