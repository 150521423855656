import { useState } from "react";
import classes from "./CSVOptions.module.css";

//icons
import full from "../../images/icon_full.png";
import firsthalf from "../../images/icon_firsthalf.png";
import secondhalf from "../../images/icon_secondhalf.png";
import firstquarter from "../../images/icon_firstquarter.png";
import secondquarter from "../../images/icon_secondquarter.png";
import thirdquarter from "../../images/icon_thirdquarter.png";
import fourthquarter from "../../images/icon_fourthquarter.png";

const CSVOptions = (props) => {
  const amounts = {
    FULL: "full",
    FIRSTHALF: "firsthalf",
    SECONDHALF: "secondhalf",
    FIRSTQUARTER: "firstqurater",
    SECONDQUARTER: "secondquarter",
    THIRDQUARTER: "thirdquarter",
    FOURTHQUARTER: "fourthquarter",
  };

  const [text, setText] = useState("Choose the amount of items you wish to import from the csv-file.");

  const HandleAmount = (event) => {
    props.handleAmount(event.target.value);
    switch (event.target.value) {
      case amounts.FULL:
        setText("Import the whole csv-file.");
        break;
      case amounts.FIRSTHALF:
        setText("Import first half of the csv-file.");
        break;
      case amounts.SECONDHALF:
        setText("Import the second half of the csv-file.");
        break;
      case amounts.FIRSTQUARTER:
        setText("Import the first quarter of the csv-file.");
        break;
      case amounts.SECONDQUARTER:
        setText("Import the second quarter of the csv-file.");
        break;
      case amounts.THIRDQUARTER:
        setText("Import the third quarter of the csv-file.");
        break;
      case amounts.FOURTHQUARTER:
        setText("Import the fourth quarter of the csv-file.");
        break;
      default:
        setText("Choose the amount of items you wish to import from the csv-file.");
    }
  };

  return (
    <div className={classes.csvoptions}>
      <h3>Options</h3>
      <h4 className={classes.h4}>Which part of the csv-file do you wish to import?</h4>
      <table>
        <tbody>
          <tr>
            <th className={classes.table}>
              <img src={full} alt='full' />
            </th>
            <th className={classes.table}>
              <img src={firsthalf} alt='firsthalf' />
            </th>
            <th className={classes.table}>
              <img src={secondhalf} alt='secondhalf' />
            </th>
            <th className={classes.table}>
              <img src={firstquarter} alt='firstquarter' />
            </th>
            <th className={classes.table}>
              <img src={secondquarter} alt='secondquarter' />
            </th>
            <th className={classes.table}>
              <img src={thirdquarter} alt='thirdquarter' />
            </th>
            <th className={classes.table}>
              <img src={fourthquarter} alt='fourthquarter' />
            </th>
          </tr>
          <tr>
            <td className={classes.table}>
              <input
                type='radio'
                id='full'
                name='amount'
                value={amounts.FULL}
                className={classes.radio}
                onClick={HandleAmount}
              />
            </td>
            <td className={classes.table}>
              <input
                type='radio'
                id='firsthalf'
                name='amount'
                value={amounts.FIRSTHALF}
                className={classes.radio}
                onClick={HandleAmount}
              />
            </td>
            <td className={classes.table}>
              <input
                type='radio'
                id='secondhalf'
                name='amount'
                value={amounts.SECONDHALF}
                className={classes.radio}
                onClick={HandleAmount}
              />
            </td>
            <td className={classes.table}>
              <input
                type='radio'
                id='firstquarter'
                name='amount'
                value={amounts.FIRSTQUARTER}
                className={classes.radio}
                onClick={HandleAmount}
              />
            </td>
            <td className={classes.table}>
              <input
                type='radio'
                id='secondquarter'
                name='amount'
                value={amounts.SECONDQUARTER}
                className={classes.radio}
                onClick={HandleAmount}
              />
            </td>
            <td className={classes.table}>
              <input
                type='radio'
                id='thirdquarter'
                name='amount'
                value={amounts.THIRDQUARTER}
                className={classes.radio}
                onClick={HandleAmount}
              />
            </td>
            <td className={classes.table}>
              <input
                type='radio'
                id='fourthquarter'
                name='amount'
                value={amounts.FOURTHQUARTER}
                className={classes.radio}
                onClick={HandleAmount}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <p className={classes.optionstext}>{text}</p>
    </div>
  );
};

export default CSVOptions;
