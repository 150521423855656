import React, { useState } from "react";
import classes from "./ImageDropfield.module.css";

import hovered from "../../images/image_delete_hovered.png";

import makeFirst from "../../images/image_makeFirst.png";
import makeSecond from "../../images/image_makeSecond.png";

const ImageDropField = (props) => {
  const visibleClass = props.visible ? classes.content : classes.contentInvisible;
  var previewImage = props.previewImage;
  const [optionsVisible, setOptionsVisible] = useState(false);
  const optionsClasss = optionsVisible ? classes.optionsField : classes.optionsFieldHidden;
  const id = props.id;
  const deletable = props.delete;

  const DeletePreview = () => {
    props.deletePreview(id);
    setOptionsVisible(false);
  };

  const MakeFirstImage = () => {
    props.makeFirstImage(id);
  };

  const MakeSecondImage = () => {
    props.makeSecondImage(id);
  };

  return (
      <div
        className={visibleClass}
        // On Drag
        onDragOver={(e) => {
          e.preventDefault();
        }}
        // On Drop
        onDrop={(e) => {
          e.preventDefault();
          Array.from(e.dataTransfer.files)
            .filter((file) => file.type === "image/png" || file.type === "image/jpeg")
            .forEach(async (file, index) => {
              const data = { id: id, image: file, number: 1 };
              props.alterImage(data);
            });
        }}
        // On Mouse Over
        onMouseOver={(e) => {
          if (deletable) {
            setOptionsVisible(true);
          }
        }}
        onMouseLeave={(e) => {
          setOptionsVisible(false);
        }}
      >
        <div className={classes.parent}>
          <div className={classes.imagePreview}>
            <img src={previewImage} alt='PreviewImage' />
          </div>
          <div className={optionsClasss}>
            <div className={classes.options}>
              <img src={makeFirst} onClick={MakeFirstImage} alt='make First in order' />
              <img src={makeSecond} onClick={MakeSecondImage} alt='make second in order' />
            </div>
            <div className={classes.deleteOption}>
              <img className={classes.deleteImage} src={hovered} onClick={DeletePreview} alt='DeletionImage' />
            </div>
          </div>
        </div>
      </div>
  );
};

export default ImageDropField;
