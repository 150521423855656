import Settings from "../settings/Settings";

import classes from "./Sidebar.module.css";
import cogwheel from "../../images/icon_sidebar.png";

const SideBar = (props) => {
  const cssClasses = props.optionsMenuOpen ? classes.sidebarOpen : classes.sidebarClosed;
  const buttonCSS = props.optionsMenuOpen ? classes.button : classes.buttonClosed;
  const ManageSidebar = () => {
    props.alterOptionsMenu();
  };

  return (
    <div className={cssClasses}>
      <div className={classes.titlebar}>
        <button className={buttonCSS} onClick={ManageSidebar}>
          <img className={classes.buttonimage} src={cogwheel} alt="Options" />
        </button>
        <Settings
          OnChangeImageSize={props.OnChangeImageSize}
          value={props.value}
          numberOfElements={props.numberOfElements}
          OnChangeNumberOfElements={props.OnChangeNumberOfElements}
          OnChangeFilterState={props.OnChangeFilterState}
          numberOfImages={props.numberOfImages}
          exportData ={props.exportData}
          exportedImages={props.exportedImages}
          exportStarted={props.exportStarted}
          numberOfItems={props.numberOfItems}
        />
      </div>
    </div>
  );
};

export default SideBar;
